<template>
  <app-tabs v-model="active">
    <el-tab-pane label="针灸、拔罐等时间设置" lazy>
      <setting-time prefix="medicalNew" />
    </el-tab-pane>
    <el-tab-pane label="灸、拔罐等项目设置" lazy>
      <setting-project prefix="medicalNew" />
    </el-tab-pane>
    <el-tab-pane label="推拿时间设置" lazy>
      <setting-time prefix="medicalNewTwo" />
    </el-tab-pane>
    <el-tab-pane label="推拿项目设置" lazy>
      <setting-project prefix="medicalNewTwo" />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import settingTime from './settingTime';
import settingProject from './settingProject';

export default {
  name: 'Setting',
  components: { settingTime, settingProject },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
